.module {
  position: relative;

  width: 570px;
  max-width: 100%;
  padding-top: 77px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 68px;
  overflow: hidden;

  background-color: #faf9f7;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 35px;

  font-size: 25px;
  font-weight: 500;
  line-height: 1.294;
  text-align: center;

  color: #02298d;
}

.city {
  margin-bottom: 15px;
}

.country {
  margin-bottom: 15px;
}

.countryString {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;

  border: 1px solid #b1bddc;
  border-radius: 6px;

  span {
    margin-bottom: 3px;

    font-size: 12px;

    color: #c1c1c1;
  }

  strong {
    font-size: 14px;
  }
}

.date {
  margin-bottom: 15px;
}

.nights {
  margin-bottom: 15px;
}

.guests {
  margin-bottom: 15px;
}

.name {
  margin-bottom: 15px;
}

.phone {
  margin-bottom: 15px;
}

.policy {
  margin-bottom: 15px;
}

.button {
  align-self: center;
  width: 100%;
  height: 58px;

  // font-weight: 600;
  text-align: center;

  border-radius: 6px;
  color: #fff;
  background-color: #ed1c24;

  transition: 0.2s;

  &:hover {
    background-color: darken(#ed1c24, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
