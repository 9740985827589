.reviews {
  $p: &;

  position: relative;

  &::before {
    position: absolute;
    top: 40px;
    left: -40px;

    width: 172px;
    height: 172px;

    border-radius: 50%;
    background-image: linear-gradient(
      80deg,
      #0d2e8e 0%,
      rgba(13, 46, 142, 0.35) 0.01%,
      rgba(13, 46, 142, 0) 100%
    );
    opacity: 0.45;

    content: '';
  }

  #reviews {
    position: absolute;
    top: -72px;
  }

  &__content {
    padding-top: 58px;
  }

  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;

    font-size: 24px;

    border-radius: 50%;
    color: #fff;
    background-color: map-get($colors, 'second');
  }

  &__title {
    margin-right: 20px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.16;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__slider {
    position: relative;
  }

  .swiper {
    margin: -20px;
    padding: 20px;
  }

  &__slide {
    padding-top: 34px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 44px;

    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);

    &_clipped {
      #{$p}__text {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      #{$p}__button {
        visibility: visible;
      }
    }
  }

  &__grade {
    margin-bottom: 17px;

    font-weight: 500;
    line-height: 1.19;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__text {
    min-height: 112px;
    margin-bottom: 11px;

    font-size: 14px;
    // line-height: 1.14;
    text-align: center;

    color: rgba(0, 0, 0, 0.71);
  }

  &__button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;

    line-height: 1.19;

    color: map-get($colors, 'second');
    visibility: hidden;
  }

  &__name {
    margin-bottom: 4px;

    font-weight: 500;
    line-height: 1.19;
    text-align: center;
    text-transform: uppercase;
  }

  &__date {
    font-weight: 14;
    line-height: 1.14;
    text-align: center;

    color: #757575;
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(241, 241, 241, 0.37);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(241, 241, 241, 0.37);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    column-gap: 9px;
    padding-top: 21px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      border: 1px solid #d9d9d9;
      border-radius: 50%;
      cursor: pointer;

      &-active {
        border-color: map-get($colors, 'second');
        background-color: map-get($colors, 'second');
      }
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    #reviews {
      top: -84px;
    }

    &::before {
      top: 110px;
    }

    &__content {
      padding-top: 82px;
    }

    &__top {
      margin-bottom: 33px;
    }

    &__title {
      font-size: 40px;
    }

    &__prev {
      &::before {
        width: 39px;
      }
    }

    &__next {
      &::before {
        width: 39px;
      }
    }

    &__pagination {
      padding-top: 34px;
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    overflow: hidden;

    &::before {
      left: 30%;
    }

    &__content {
      padding-top: 99px;
    }

    .swiper {
      overflow: visible;

      .swiper-slide {
        opacity: 0.6;

        pointer-events: none;
      }

      .swiper-slide-active,
      .swiper-slide-active + .swiper-slide,
      .swiper-slide-active + .swiper-slide + .swiper-slide {
        opacity: 1;

        pointer-events: initial;
      }
    }

    &__slide {
      padding-left: 35px;
      padding-right: 35px;
    }

    &__prev {
      left: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 29px;
        left: 30px;

        width: 11px;
      }
    }

    &__next {
      right: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 29px;
        right: 30px;

        width: 11px;
      }
    }

    &__pagination {
      padding-top: 44px;
    }
  }
}
