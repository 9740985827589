.contacts {
  position: relative;

  #contacts {
    position: absolute;
    top: -72px;
  }

  &__map {
    height: 400px;
  }

  &__block {
    // position: absolute;
    // top: 45px;
    // left: 20px;
    // right: 20px;

    padding-top: 27px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;

    border-radius: 6px;
    background-color: #fff;
    // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
  }

  &__title {
    margin-bottom: 18px;

    font-size: 26px;
    font-weight: 500;
    line-height: 1.15;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__email {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 12px;
      margin-right: 8px;
    }

    a {
      line-height: 1.19;
    }
  }

  &__address {
    display: flex;
    align-items: flex-start;
    margin-bottom: 9px;

    img {
      width: 9px;
      margin-right: 5px;
    }

    span {
      font-size: 12px;
      line-height: 1.17;

      color: #494949;
    }
  }

  &__phone {
    display: flex;
    margin-left: 15px;

    font-weight: 600;
    line-height: 1.19;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

// @media (min-width: 576px) {
//   .contacts {
//     &__block {
//       margin-left: calc((100vw - 540px) / -2);
//       margin-right: calc((100vw - 540px) / -2);
//     }
//   }
// }

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -84px;
    }

    &__map {
      height: 465px;
    }

    &__block {
      // top: 80px;
      // left: calc((100vw - 720px) / 2);
      // right: auto;

      width: 338px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 25px;
      padding-right: 25px;
    }

    &__address {
      margin-bottom: 15px;

      span {
        font-size: 14px;
      }
    }

    &__phone {
      &:not(:last-child) {
        margin-bottom: 27px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    &__map {
      height: 498px;
    }

    &__block {
      position: absolute;
      top: 80px;
      left: calc((100vw - 1140px) / 2);

      padding-top: 38px;
      padding-left: 36px;
      padding-right: 36px;
      padding-bottom: 53px;

      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
    }
  }
}
