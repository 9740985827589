@import '/src/styles/utils/functions';

$corner-color: #b1bddc;

.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  padding-left: 12px;

  border: 1px solid #b1bddc;
  border-radius: 6px;
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 1.14;
  }

  strong {
    font-size: 14px;
    line-height: 1.14;
  }

  &.filled {
    span {
      margin-bottom: 3px;

      font-size: 12px;

      color: #c1c1c1;
    }
  }
}

.wrapper {
  max-height: 300px;
  overflow-y: scroll;

  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
}

.list {
  display: flex;
  flex-direction: column;
  width: 215px;
  padding-top: 11px;
  padding-bottom: 18px;

  background-color: #fff;
}

.item {
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;

  color: #555;
  cursor: pointer;

  transition: color 0.2s;

  img {
    width: 16px;
    margin-right: 12px;
  }

  &:hover {
    color: #000;
  }
}

@media (min-width: 768px) {
  .list {
    display: block;
    width: 365px;
    columns: 2;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    max-height: none;
    overflow-y: initial;
  }

  .list {
    width: 600px;
    columns: 3;
  }
}
