.module {
  position: relative;

  width: 570px;
  max-width: 100%;
  padding-top: 77px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 67px;
  overflow: hidden;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 35px;

  font-size: 25px;
  font-weight: 500;
  line-height: 1.294;
  text-align: center;

  color: #02298d;
}

.name {
  margin-bottom: 15px;
}

.phone {
  margin-bottom: 15px;
}

.policy {
  margin-bottom: 15px;
}

.button {
  align-self: center;
  width: 100%;
  height: 58px;

  // font-weight: 600;
  text-align: center;

  border-radius: 6px;
  color: #fff;
  background-color: #ed1c24;

  transition: 0.2s;

  &:hover {
    background-color: darken(#ed1c24, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
