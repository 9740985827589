.module {
  position: relative;
  width: 42px;
  height: 42px;

  border: none;
  background-color: transparent;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 26px;
    height: 1px;

    background-color: #a0a7a6;
    transform: translate(-50%, -50%) rotate(45deg);

    transition: background 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 26px;
    height: 1px;

    background-color: #a0a7a6;
    transform: translate(-50%, -50%) rotate(-45deg);

    transition: background 0.2s;

    content: '';
  }

  &:hover {
    &::before {
      background-color: #000;
    }

    &::after {
      background-color: #000;
    }
  }
}
