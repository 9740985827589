@import '/src/styles/utils/functions';

$corner-color: #b1bddc;

.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  padding-left: 12px;

  border: 1px solid #b1bddc;
  border-radius: 6px;
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 1.14;
  }

  strong {
    font-size: 14px;
    line-height: 1.14;
  }

  &.filled {
    span {
      margin-bottom: 3px;

      font-size: 12px;

      color: #c1c1c1;
    }
  }
}
