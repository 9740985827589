.screen {
  position: relative;

  overflow: hidden;

  padding-top: 72px;

  background-color: map-get($colors, 'bg');

  &::before {
    position: absolute;
    bottom: -160px;
    right: -100px;

    display: flex;
    width: 309px;
    height: 309px;

    border-radius: 50%;
    background-image: linear-gradient(
      180deg,
      rgba(13, 46, 142, 0.16) 0%,
      rgba(13, 46, 142, 0) 100%
    );
    opacity: 0.45;

    content: '';
  }

  &__content {
    position: relative;

    padding-top: 46px;
    padding-bottom: 175px;
  }

  &__textual {
    margin-bottom: 18px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;

    strong {
      margin-bottom: 4px;

      font-size: 38px;
      font-weight: 500;
      line-height: 0.7889;
      text-align: center;
      text-transform: uppercase;

      color: map-get($colors, 'main');
    }

    span {
      font-size: 26px;
      line-height: 1.109;
      text-align: center;

      color: map-get($colors, 'main');
    }
  }

  &__promo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 34px;
      margin-right: 15px;
    }

    span {
      max-width: 210px;

      font-size: 12px;
      line-height: 1.349;

      color: #1b1b1b;
    }
  }

  &__image {
    display: flex;
    width: 218px;
    margin-left: auto;
    margin-right: auto;

    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}

// @media (min-width: 576px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 540px) / -2 - 20px);
//       margin-right: calc((100vw - 540px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 768px) {
  .screen {
    padding-top: 84px;

    &::before {
      left: 50px;
      right: auto;
      bottom: -220px;
    }

    &__content {
      display: flex;

      padding-bottom: 129px;
    }

    &__textual {
      margin-right: auto;
    }

    &__title {
      margin-bottom: 43px;

      strong {
        margin-bottom: 6px;

        font-size: 54px;
      }

      span {
        font-size: 38px;
        text-align: left;
      }
    }

    &__promo {
      justify-content: flex-start;

      span {
        max-width: 260px;

        font-size: 13px;
      }
    }

    &__image {
      width: 283px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// @media (min-width: 992px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 960px) / -2 - 20px);
//     }

//     &__image {
//       left: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .screen {
    &::before {
      left: -150px;
      bottom: 80px;

      width: 250px;
      height: 250px;
    }

    &::after {
      position: absolute;
      top: 200px;
      right: -300px;

      width: 469px;
      height: 469px;

      border-radius: 50%;
      background-image: linear-gradient(
        180deg,
        rgba(13, 46, 142, 0.16) 0%,
        rgba(13, 46, 142, 0) 100%
      );
      opacity: 0.45;

      content: '';
    }

    &__content {
      position: relative;

      padding-top: 50px;
      padding-bottom: 145px;

      &::before {
        position: absolute;
        top: 77px;
        left: 120px;

        width: 114px;
        height: 114px;

        border-radius: 50%;
        background-image: linear-gradient(
          180deg,
          #0d2e8e 0%,
          rgba(13, 46, 142, 0.35) 0.01%,
          rgba(13, 46, 142, 0) 100%
        );
        opacity: 0.45;

        content: '';
      }

      &::after {
        position: absolute;
        left: 30%;
        bottom: -170px;

        width: 309px;
        height: 309px;

        border-radius: 50%;
        background-image: linear-gradient(
          120deg,
          rgba(13, 46, 142, 0.16) 0%,
          rgba(13, 46, 142, 0) 100%
        );
        opacity: 0.45;

        content: '';
      }
    }

    &__textual {
      padding-top: 134px;
    }

    &__title {
      margin-bottom: 51px;

      strong {
        margin-bottom: 8px;

        font-size: 65px;
      }

      span {
        font-size: 45px;
      }
    }

    &__promo {
      img {
        width: 41px;
        margin-right: 18px;
      }

      span {
        max-width: 320px;

        font-size: 16px;
      }
    }

    &__image {
      width: auto;
      height: 432px;
      margin-right: 120px;
    }
  }
}
