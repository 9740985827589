.gallery {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-bottom: 45px;
  }

  &__first {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
  }

  &__second {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
  }

  &__item {
    display: flex;
    width: calc(50% - 15px / 2);

    img {
      width: 100%;
      aspect-ratio: 1 / 1.37;
      object-fit: cover;

      border-radius: 5px;
    }

    &_long {
      width: 100%;

      img {
        aspect-ratio: 1 / 0.65;
      }
    }
  }

  &__slider {
    position: relative;

    width: 100%;

    .swiper-button-lock {
      display: none;
    }
  }

  &__slide {
    position: relative;

    display: flex;

    img {
      width: 100%;
      aspect-ratio: 1 / 0.65;
      object-fit: cover;

      border-radius: 5px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 30px;
      height: 30px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
      transform: translate(-50%, -50%);

      content: '';
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 8px;
      height: 10px;
      margin-left: 1px;

      background-color: map-get($colors, 'main');
      clip-path: polygon(0 0, 100% 50%, 0 100%);
      transform: translate(-50%, -50%);

      content: '';
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 24px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: #fff;

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      left: 20px;

      width: 4px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 24px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: #fff;

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      right: 20px;

      width: 4px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .gallery {
    position: relative;

    &::before {
      position: absolute;
      top: -150px;
      left: -120px;

      width: 469px;
      height: 469px;

      border-radius: 50%;
      background-image: linear-gradient(
        180deg,
        rgba(13, 46, 142, 0.16) 0%,
        rgba(13, 46, 142, 0) 100%
      );
      opacity: 0.45;

      content: '';
    }

    &__content {
      padding-bottom: 80px;
    }

    &__first {
      column-gap: 20px;
      margin-bottom: 20px;
    }

    &__item {
      width: calc(25% - 30px / 2 * 1);

      &_long {
        order: -1;
        width: calc(50% - 20px / 2 * 1);
      }
    }

    &__slider {
      width: calc(50% - 20px / 2 * 1);
    }

    &__second {
      column-gap: 20px;
    }

    &__prev {
      &::before {
        width: 30px;
      }

      &::after {
        width: 5px;
      }
    }

    &__next {
      &::before {
        width: 30px;
      }

      &::after {
        width: 5px;
      }
    }

    &__slide {
      &::before {
        width: 38px;
        height: 38px;
      }

      &::after {
        width: 10px;
        height: 12px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .gallery {
    &::before {
      top: auto;
      bottom: 20px;
    }

    &__content {
      padding-bottom: 102px;
    }

    &__first {
      column-gap: 30px;
      margin-bottom: 30px;
    }

    &__item {
      width: calc(25% - 45px / 2 * 1);

      &_long {
        order: -1;
        width: calc(50% - 30px / 2 * 1);
      }
    }

    &__slider {
      width: calc(50% - 30px / 2 * 1);
    }

    &__slide {
      &::before {
        width: 60px;
        height: 60px;
      }

      &::after {
        width: 14px;
        height: 16px;
      }
    }

    &__prev {
      left: 20px;

      width: 49px;

      &::before {
        width: 49px;
      }

      &::after {
        top: 21px;
        left: 22px;

        width: 8px;
      }
    }

    &__next {
      right: 20px;

      width: 49px;

      &::before {
        width: 49px;
      }

      &::after {
        top: 21px;
        right: 22px;

        width: 8px;
      }
    }

    &__second {
      column-gap: 30px;
    }
  }
}
