.description {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 40px;
    padding-bottom: 32px;
  }

  &__first {
    margin-bottom: 44px;
  }

  &__title {
    position: relative;

    margin-bottom: 19px;
    padding-bottom: 38px;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.554;
    text-align: center;
    text-transform: uppercase;

    span {
      color: map-get($colors, 'main');
    }

    &::before {
      position: absolute;
      top: 100%;
      left: 50%;

      width: 135px;
      height: 3px;

      border-radius: 3px;
      background-color: map-get($colors, 'main');
      transform: translateX(-50%);

      content: '';
    }
  }

  &__team {
    font-size: 14px;
    line-height: 1.327;
    text-align: center;

    color: #494949;
  }

  &__subtitle {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;

    i {
      position: relative;

      width: 24px;
      height: 24px;
      margin-right: 8px;

      border-radius: 50%;
      background-color: #fff;

      &::before {
        position: absolute;
        top: 8px;
        left: 8px;

        width: 8px;
        height: 5px;

        border-left: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        transform: rotate(-45deg);

        content: '';
      }
    }

    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.17;
      text-transform: uppercase;

      color: map-get($colors, 'main');
    }
  }

  &__text {
    p {
      font-size: 14px;
      line-height: 1.3269;
      text-align: center;

      color: #494949;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    span {
      color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .description {
    position: relative;

    overflow: hidden;

    &::before {
      position: absolute;
      top: 260px;
      right: -50px;

      width: 114px;
      height: 114px;

      border-radius: 50%;
      background-image: linear-gradient(
        244deg,
        #0d2e8e 0%,
        rgba(13, 46, 142, 0.35) 0.01%,
        rgba(13, 46, 142, 0) 100%
      );
      opacity: 0.45;

      content: '';
    }

    &__content {
      padding-top: 79px;
      padding-bottom: 51px;
    }

    &__first {
      margin-bottom: 51px;
    }

    &__title {
      margin-bottom: 33px;
      padding-bottom: 18px;

      font-size: 24px;
      text-align: left;

      &::before {
        left: 0;

        transform: translateX(0);
      }
    }

    &__team {
      font-size: 16px;
      text-align: left;
    }

    &__subtitle {
      justify-content: start;

      span {
        font-size: 23px;
      }
    }

    &__text {
      p {
        font-size: 16px;
        text-align: left;
      }
    }
  }
}

@media (min-width: 1200px) {
  .description {
    &::before {
      top: auto;
      bottom: 0;
    }

    &__content {
      display: flex;
      column-gap: 30px;
      padding-bottom: 84px;
    }

    &__first {
      width: calc(50% - 15px);
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 27px;
      padding-bottom: 45px;
    }

    &__second {
      width: calc(50% - 15px);
    }
  }
}
