.team {
  position: relative;

  #team {
    position: absolute;
    top: -72px;
  }

  &__content {
    padding-top: 40px;
    padding-bottom: 66px;
  }

  &__title {
    margin-bottom: 18px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  &__item {
    overflow: hidden;

    border-radius: 5px;
    background-color: map-get($colors, 'bg');
  }

  &__image {
    display: flex;

    img {
      width: 100%;
      aspect-ratio: 1 / 0.8;
      object-fit: cover;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 19px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
  }

  &__name {
    margin-bottom: 4px;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    text-align: center;
  }

  &__branch {
    min-height: 34px;
    margin-bottom: 14px;

    font-size: 13px;
    line-height: 1.289;
    text-align: center;

    color: rgba(0, 0, 0, 0.53);
  }

  &__phone {
    margin-bottom: 9px;

    font-size: 18px;
    font-weight: 700;
    line-height: 1.17;

    color: map-get($colors, 'main');
  }

  &__whatsapp {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      margin-right: 5px;
    }

    span {
      // font-weight: 600;
      line-height: 1.375;

      color: #4caf50;
    }
  }
}

@media (min-width: 768px) {
  .team {
    #team {
      top: -84px;
    }

    &__content {
      padding-top: 75px;
      padding-bottom: 82px;
    }

    &__title {
      margin-bottom: 43px;

      font-size: 40px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 30px;
    }

    &__item {
      width: calc(50% - 15px);
    }

    &__image {
      img {
        aspect-ratio: 1 / 0.65;
      }
    }
  }
}

@media (min-width: 1200px) {
  .team {
    overflow: hidden;

    &::before {
      position: absolute;
      top: 50px;
      right: -60px;

      width: 114px;
      height: 114px;

      border-radius: 50%;
      background-image: linear-gradient(
        310deg,
        #0d2e8e 0%,
        rgba(13, 46, 142, 0.35) 0.01%,
        rgba(13, 46, 142, 0) 100%
      );
      opacity: 0.45;

      content: '';
    }

    &__content {
      padding-top: 64px;
      padding-bottom: 98px;
    }

    &__item {
      width: calc(25% - 30px / 4 * 3);
    }

    &__image {
      img {
        aspect-ratio: 1 / 0.84;
      }
    }

    &__body {
    }
  }
}
