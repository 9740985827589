.awards {
  position: relative;

  overflow: hidden;

  background-color: map-get($colors, 'bg');

  &::before {
    position: absolute;
    top: 100px;
    left: -300px;

    width: 469px;
    height: 469px;

    border-radius: 50%;
    background-image: linear-gradient(
      180deg,
      rgba(13, 46, 142, 0.16) 0%,
      rgba(13, 46, 142, 0) 100%
    );
    opacity: 0.45;

    content: '';
  }

  &__content {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 24px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.16;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__slider {
    position: relative;

    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper {
    overflow: visible;

    .swiper-slide {
      &:not(.swiper-slide-active) {
        opacity: 0.6;

        pointer-events: none;
      }
    }
  }

  &__slide {
    img {
      width: 100%;
      aspect-ratio: 1 / 1.45;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -60px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.77);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -60px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.77);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .awards {
    &::before {
      left: -200px;
    }

    &__content {
      padding-top: 72px;
      padding-bottom: 84px;
    }

    &__title {
      margin-bottom: 43px;

      font-size: 40px;
    }

    &__slider {
      width: 600px;

      .swiper-slide-active + .swiper-slide {
        opacity: 1 !important;

        pointer-events: initial !important;
      }
    }

    &__prev {
      &::before {
        width: 39px;
      }
    }

    &__next {
      &::before {
        width: 39px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .awards {
    &__content {
      padding-top: 68px;
      padding-bottom: 89px;
    }

    &__title {
      margin-bottom: 39px;
    }

    &__slider {
      width: auto;

      .swiper-slide-active + .swiper-slide,
      .swiper-slide-active + .swiper-slide + .swiper-slide,
      .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
        opacity: 1 !important;

        pointer-events: initial !important;
      }
    }

    &__prev {
      left: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 30px;
        left: 31px;

        width: 11px;
      }
    }

    &__next {
      right: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 30px;
        right: 31px;

        width: 11px;
      }
    }
  }
}
