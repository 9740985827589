.hot {
  position: relative;

  background-color: map-get($colors, 'bg');

  #hot {
    position: absolute;
    top: -72px;
  }

  &__content {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 26px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.16;
    text-align: center;
    text-transform: uppercase;

    color: #ff8514;
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    padding-top: 12px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 13px;

    border-radius: 5px;
    background-color: #fff;
  }

  &__flame {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;

    border-radius: 50%;
    background-color: #ff8d04;
  }

  &__wrapper {
    position: relative;

    margin-bottom: 13px;
    overflow: hidden;
  }

  &__images {
    display: flex;

    transition: 0.2s;
  }

  &__image {
    position: relative;

    flex: 1 0 100%;
    padding-bottom: 68%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 5px;
    }
  }

  &__inner-nav {
  }

  &__inner-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 9px;

    width: 33px;
    height: 33px;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.77);
    backdrop-filter: blur(5px);
    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 14px;
      left: 15px;

      width: 6px;
      height: 6px;

      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      transform: rotate(45deg);

      content: '';
    }
  }

  &__inner-next {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 9px;

    width: 33px;
    height: 33px;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.77);
    backdrop-filter: blur(5px);
    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 14px;
      right: 15px;

      width: 6px;
      height: 6px;

      border-right: 1px solid #000;
      border-top: 1px solid #000;
      transform: rotate(45deg);

      content: '';
    }
  }

  &__inner-pagination {
    position: absolute;
    left: 50%;
    bottom: 13px;

    display: flex;

    transform: translateX(-50%);
  }

  &__inner-pagination-item {
    width: 8px;
    height: 8px;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.42);

    &:not(:last-child) {
      margin-right: 7px;
    }

    &--active {
      background-color: map-get($colors, 'second');
    }
  }

  &__stars {
    display: flex;
    column-gap: 10px;
    margin-bottom: 8px;

    img {
      width: 16px;
    }
  }

  &__type {
    display: inline-flex;
    align-items: center;
    height: 21px;
    margin-bottom: 5px;
    padding-left: 9px;
    padding-right: 9px;

    font-size: 12px;

    border-radius: 2px;
    color: rgba(2, 41, 141, 0.4);
    background-color: map-get($colors, 'bg');
  }

  &__name {
    min-height: 46px;
    margin-bottom: 9px;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    text-transform: uppercase;
  }

  &__address {
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    img {
      width: 11px;
      margin-right: 9px;
    }

    span {
      font-size: 13px;
      line-height: 1.289;

      color: rgba(0, 0, 0, 0.53);
    }
  }

  &__days {
    display: inline-flex;
    align-items: center;
    height: 22px;
    margin-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;

    font-size: 14px;

    border-radius: 2px;
    color: map-get($colors, 'main');
    background-color: map-get($colors, 'bg');
  }

  &__prices {
    display: flex;
    align-items: baseline;
    margin-bottom: 17px;

    del {
      margin-right: 19px;

      font-size: 20px;
      line-height: 1.15;
      text-decoration: line-through;

      color: #878787;
    }

    strong {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.15;

      color: map-get($colors, 'main');
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

    // font-weight: 600;

    border-radius: 6px;
    color: #fff;
    background-color: map-get($colors, 'second');
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.77);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      left: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 28px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.77);
      backdrop-filter: blur(5px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 18px;
      right: 19px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .hot {
    #hot {
      top: -84px;
    }

    &__content {
      padding-top: 77px;
      padding-bottom: 83px;
    }

    &__title {
      margin-bottom: 30px;

      font-size: 40px;
    }

    &__slide {
      padding-top: 18px;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 29px;
    }

    &__button {
      width: 214px;
    }

    &__prev {
      &::before {
        width: 39px;
      }
    }

    &__next {
      &::before {
        width: 39px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .hot {
    $p: &;

    overflow: hidden;

    &__content {
      padding-top: 65px;
      padding-bottom: 128px;
    }

    &__title {
      margin-bottom: 35px;
    }

    .swiper {
      overflow: visible;

      .swiper-slide {
        opacity: 0.6;

        pointer-events: none;
      }

      .swiper-slide-active,
      .swiper-slide-active + .swiper-slide,
      .swiper-slide-active + .swiper-slide + .swiper-slide {
        opacity: 1;

        pointer-events: initial;
      }
    }

    &__slide {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 19px;
    }

    &__flame {
      top: 40px;
      right: 40px;
    }

    &__wrapper {
      &:hover {
        #{$p}__inner-nav {
          opacity: 1;
        }

        #{$p}__inner-pagination {
          opacity: 1;
        }
      }
    }

    &__address {
      margin-bottom: 29px;
    }

    &__prices {
      margin-bottom: 23px;
    }

    &__inner-nav {
      opacity: 0;

      transition: opacity 0.2s;
    }

    &__inner-pagination {
      opacity: 0;

      transition: opacity 0.2s;
    }

    &__prev {
      left: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 30px;
        left: 31px;

        width: 11px;
      }
    }

    &__next {
      right: -34px;

      width: 68px;

      &::before {
        width: 68px;
      }

      &::after {
        top: 30px;
        right: 31px;

        width: 11px;
      }
    }
  }
}
