.quiz {
  position: relative;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(247, 249, 251, 0.86);

    content: '';
  }

  &__content {
    position: relative;

    padding-top: 44px;
    padding-bottom: 65px;
  }

  &__title {
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.264;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__subtitle {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;

    line-height: 1.3991;
    text-align: center;

    color: map-get($colors, 'main');
  }
}

@media (min-width: 768px) {
  .quiz {
    &__content {
      display: flex;
      padding-top: 90px;
      padding-bottom: 130px;
    }

    &__textual {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: auto;
    }

    &__title {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 15px;

      font-size: 40px;
      text-align: left;
    }

    &__subtitle {
      max-width: 330px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;

      font-size: 20px;
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .quiz {
    &__content {
      padding-top: 102px;
      padding-bottom: 94px;
    }

    &__title {
      max-width: none;
      margin-bottom: 29px;
    }
  }
}
