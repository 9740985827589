.module {
  min-height: 155px;
  padding: 12px 16px;

  font-size: 14px;

  border: 1px solid #b1bddc;
  border-radius: 6px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.69);
  }
}
