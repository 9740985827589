.form {
  &__content {
    position: relative;

    margin-top: -126px;
    padding-top: 21px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 16px;

    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
  }

  &__hot {
    position: absolute;
    left: 50%;
    bottom: 100%;

    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #ff8d04;
    transform: translateX(-50%);

    span {
      margin-right: 5px;

      font-size: 12px;

      color: #fff;
    }
  }
}

@media (min-width: 768px) {
  .form {
    &__content {
      width: 604px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -51px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 21px;
    }

    &__hot {
      left: auto;
      right: 0;

      width: 210px;
      height: 43px;

      transform: translateX(0);

      span {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .form {
    &__content {
      width: 100%;
      margin-top: -47px;
      padding-bottom: 23px;
    }
  }
}
