.callback {
  &__content {
    padding-top: 27px;
    padding-bottom: 45px;
  }

  &__map {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;

    background-repeat: no-repeat;
    background-size: cover;
  }

  &__images {
    position: relative;

    height: 326px;

    span {
      display: flex;

      &:nth-child(1) {
        position: absolute;
        top: 40px;
        right: 58px;

        width: 39px;
        height: 39px;
      }

      &:nth-child(2) {
        position: absolute;
        top: 84px;
        left: 16px;

        width: 42px;
        height: 42px;
      }

      &:nth-child(3) {
        position: absolute;
        top: 117px;
        left: 106px;

        width: 83px;
        height: 83px;
      }

      &:nth-child(4) {
        position: absolute;
        top: 133px;
        right: 0;

        width: 51px;
        height: 51px;
      }

      &:nth-child(5) {
        position: absolute;
        left: 36px;
        bottom: 86px;

        width: 54px;
        height: 54px;
      }

      &:nth-child(6) {
        position: absolute;
        right: 46px;
        bottom: 51px;

        width: 35px;
        height: 35px;
      }
    }

    img {
      width: 100%;

      object-fit: cover;

      border-radius: 50%;
    }
  }

  &__title {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 11px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.264;
    text-transform: uppercase;
    text-align: center;

    color: map-get($colors, 'main');
  }

  &__subtitle {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px;

    font-size: 20px;
    line-height: 1.15;
    text-align: center;

    color: map-get($colors, 'main');
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

    // font-weight: 600;

    border-radius: 6px;
    color: #fff;
    background-color: map-get($colors, 'second');
  }
}

@media (min-width: 768px) {
  .callback {
    &__content {
      display: flex;
      padding-top: 71px;
      padding-bottom: 33px;
    }

    &__map {
      flex-grow: 1;
      order: 1;
      width: 50%;
      margin-left: 0;
      margin-right: calc((100vw - 720px) / -2);
      margin-bottom: 0;
    }

    &__textual {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }

    &__title {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 18px;

      font-size: 40px;
      text-align: left;
    }

    &__subtitle {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 52px;

      text-align: left;
    }

    &__button {
      width: 223px;
      height: 58px;
    }
  }
}

@media (min-width: 1200px) {
  .callback {
    &__content {
      padding-top: 103px;
      padding-bottom: 102px;
    }

    &__map {
      width: 60%;
      margin-right: 0;
      padding-left: 125px;
      padding-right: 125px;
    }

    &__images {
      height: 425px;

      span {
        &:nth-child(1) {
          width: 51px;
          height: 51px;
        }

        &:nth-child(2) {
          width: 59px;
          height: 59px;
        }

        &:nth-child(3) {
          width: 117px;
          height: 117px;
        }

        &:nth-child(4) {
          width: 71px;
          height: 71px;
        }

        &:nth-child(5) {
          position: absolute;
          left: 36px;
          bottom: 86px;

          width: 75px;
          height: 75px;
        }

        &:nth-child(6) {
          width: 49px;
          height: 49px;
        }
      }
    }

    &__title {
      max-width: 380px;
    }
  }
}
