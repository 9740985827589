.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nights {
  margin-bottom: 10px;

  background-color: #fff;
}

.guests {
  background-color: #fff;
}
