.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 770px;
  max-width: 100%;
  padding-top: 89px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 78px;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.icon {
  position: relative;

  width: 54px;
  aspect-ratio: 1;
  margin-bottom: 30px;

  border-radius: 50%;
  background-color: #ff8d04;

  &::before {
    position: absolute;
    top: 19px;
    left: 17px;

    width: 21px;
    height: 11px;

    border-left: 1px solid rgba(255, 255, 255, 0.63);
    border-bottom: 1px solid rgba(255, 255, 255, 0.63);
    transform: rotate(-45deg);

    content: '';
  }
}

.title {
  margin-bottom: 5px;

  font-size: 25px;
  font-weight: 500;
  line-height: 1.294;
  text-align: center;

  color: #02298d;
}

.subtitle {
  margin-bottom: 20px;

  line-height: 1.55;
  text-align: center;

  color: #494949;
}

.button {
  align-self: center;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;

  // font-weight: 600;
  text-align: center;

  border-radius: 6px;
  color: #fff;
  background-color: #ed1c24;

  transition: 0.2s;

  &:hover {
    background-color: darken(#ed1c24, 10%);
  }
}

@media (min-width: 1200px) {
}
