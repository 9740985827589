.directions {
  $p: &;

  position: relative;

  #directions {
    position: absolute;
    top: -72px;
  }

  &__content {
    padding-top: 45px;
    padding-bottom: 44px;
  }

  &__title {
    margin-bottom: 22px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 32px;
  }

  &__item {
    padding-top: 9px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 12px;

    border-radius: 5px;
    background-color: map-get($colors, 'bg');
  }

  &__image {
    display: flex;
    margin-bottom: 16px;

    img {
      width: 100%;
      aspect-ratio: 1 / 0.59;
      object-fit: cover;

      border-radius: 5px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__country {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    img {
      width: 16px;
      margin-right: 11px;
    }

    span {
      font-size: 25px;
      font-weight: 500;
      line-height: 1.16;

      color: map-get($colors, 'main');
    }
  }

  &__price {
    margin-bottom: 17px;

    font-size: 21px;
    font-weight: 600;
    line-height: 1.19;

    color: map-get($colors, 'main');
  }

  &__text {
    max-width: 200px;
    margin-bottom: 22px;

    font-size: 13px;
    line-height: 1.289;
    text-align: center;

    color: rgba(0, 0, 0, 0.53);
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

    // font-weight: 600;

    border-radius: 6px;
    color: #fff;
    background-color: map-get($colors, 'second');
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    font-weight: 700;
    line-height: 1.19;
    text-transform: uppercase;

    color: map-get($colors, 'main');

    &::after {
      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'main');
      border-bottom: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }
  }

  &:not(#{$p}_opened) {
    #{$p}__item {
      &:nth-child(n + 5) {
        display: none;
      }
    }
  }

  &_opened {
    #{$p}__list {
      margin-bottom: 0;
    }

    #{$p}__more {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .directions {
    #directions {
      top: -84px;
    }

    &__content {
      padding-top: 70px;
      padding-bottom: 83px;
    }

    &__title {
      margin-bottom: 31px;

      font-size: 40px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 30px;
    }

    &__item {
      width: calc(50% - 15px);
      padding-top: 20px;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 26px;
    }

    &__image {
      margin-bottom: 13px;
    }

    &__block {
      align-items: flex-start;
    }

    &__text {
      text-align: left;
    }

    &__button {
      width: 214px;
    }
  }
}

@media (min-width: 1200px) {
  .directions {
    &::before {
      position: absolute;
      top: 90px;
      left: -50px;

      width: 104px;
      height: 104px;

      border-radius: 50%;
      background-image: linear-gradient(
        30deg,
        #0d2e8e 0%,
        rgba(13, 46, 142, 0.35) 0.01%,
        rgba(13, 46, 142, 0) 100%
      );
      opacity: 0.45;

      content: '';
    }

    &__content {
      padding-top: 102px;
      padding-bottom: 104px;
    }

    &__title {
      margin-bottom: 58px;
    }

    &__item {
      display: flex;
      padding-left: 39px;
      padding-right: 21px;
      padding-bottom: 23px;
    }

    &__image {
      order: 1;
      width: 260px;
      margin-bottom: 0;
    }

    &__block {
      flex-grow: 1;
    }

    &__price {
      margin-bottom: 19px;
    }

    &__text {
      margin-bottom: 42px;
    }

    &__button {
      width: 200px;
    }
  }
}
