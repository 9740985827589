.module {
  height: 50px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  font-size: 14px;

  border: 1px solid #b1bddc;
  border-radius: 6px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.69);
  }
}

@media (min-width: 768px) {
}
