.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  margin-bottom: 10px;

  background-color: #fff;
}

.phone {
  margin-bottom: 10px;

  background-color: #fff;
}
