$color: #92855b;

:root {
  --rdp-cell-size: 36px !important;
  --rdp-accent-color: rgba(237, 28, 36, 0.08) !important;
  --rdp-background-color: rgba(237, 28, 36, 0.08) !important;
  --rdp-accent-color-dark: pink !important;
  --rdp-background-color-dark: green !important;
  --rdp-outline: 2px solid var(--rdp-accent-color) !important;
  --rdp-outline-selected: 2px solid transparent !important;
}

.module {
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;

  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);

  :global {
    .rdp {
      margin: 0;
    }

    .rdp-caption {
      position: relative;

      justify-content: center;
      height: 40px;
    }

    .rdp-caption_label {
      font-size: 18px;
      font-weight: 600;
    }

    .rdp-nav_button_previous {
      position: absolute;
      top: 0;
      left: 0;
    }

    .rdp-nav_button_next {
      position: absolute;
      top: 0;
      right: 0;
    }

    .rdp-table {
      max-width: calc(30px * 7);
    }

    .rdp-head_cell {
      font-size: 14px;
      font-weight: 400;
      text-transform: none;

      color: rgba(126, 126, 126, 0.58);
    }

    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]),
    .rdp-day_selected:hover:not([disabled]) {
      color: #000;
    }

    .rdp-day {
      border-radius: 3px !important;
    }

    .rdp-day_range_end,
    .rdp-day_range_start {
      border-radius: 3px !important;

      color: #fff !important;
      background-color: #ed1c24 !important;
    }
  }
}
