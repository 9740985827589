.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.region {
  margin-bottom: 10px;

  background-color: #fff;
}

.date {
  background-color: #fff;
}
