.header {
  $p: &;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;

  background-color: map-get($colors, 'bg');

  // &_sticked {
  //   background-color: map-get($colors, 'main');

  //   transition: 0.2s;
  // }

  &__content {
    position: relative;

    display: flex;
    align-items: flex-start;
    height: 72px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &__logo {
    display: flex;
    align-self: center;
    width: 80px;

    margin-right: auto;
  }

  &__nav {
    display: none;

    a {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      transition: border 0.2s, color 0.2s;
    }

    a:hover {
      border-bottom-color: map-get($colors, 'main');
      color: map-get($colors, 'main');
    }
  }

  &__networks {
    display: flex;
    column-gap: 10px;
    margin-right: 15px;
    padding-top: 7px;

    a {
      display: flex;
    }

    img {
      width: 18px;
    }
  }

  &__phone {
    position: absolute;
    right: 0;
    bottom: 6px;

    display: flex;
    align-items: center;

    img {
      width: 17px;
      margin-right: 8px;
    }

    span {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.17;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;

    border-radius: 50%;
    background-color: #fff;

    &::before {
      width: 12px;
      height: 1px;
      margin-bottom: 3px;

      background-color: map-get($colors, 'main');

      transition: background 0.2s;

      content: '';
    }

    &::after {
      width: 12px;
      height: 1px;

      transition: background 0.2s;

      background-color: map-get($colors, 'main');

      content: '';
    }

    span {
      width: 12px;
      height: 1px;
      margin-bottom: 3px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      background-color: map-get($colors, 'main');

      transition: background 0.2s;
    }

    // &:hover {
    //   span {
    //     background-color: #000;
    //   }

    //   &::before {
    //     background-color: #000;
    //   }

    //   &::after {
    //     background-color: #000;
    //   }
    // }
  }

  &_opened {
    #{$p}__nav {
      position: absolute;
      z-index: 1;
      top: 0;
      right: -20px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 30px;
      width: 252px;
      padding-top: 71px;
      padding-right: 20px;
      padding-bottom: 64px;

      background-color: #fff;

      a {
        font-size: 14px;
        line-height: 1.14;
      }
    }

    #{$p}__button {
      position: relative;
      z-index: 1;

      align-items: center;

      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);

      span {
        display: none;
      }

      &::before {
        width: 12px;
        margin-bottom: 0;

        transform: rotate(45deg);
      }

      &::after {
        width: 12px;
        margin-top: -1px;

        transform: rotate(-45deg);
      }
    }
  }
}

// @media (min-width: 576px) {
//   .contacts {
//     &__map {
//       margin-left: calc((100vw - 540px) / -2 - 15px);
//       margin-right: calc((100vw - 540px) / -2 - 15px);
//     }
//   }
// }

@media (min-width: 768px) {
  .header {
    $p: &;

    &__content {
      height: 84px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &__logo {
      width: 100px;
    }

    &__networks {
      margin-right: 22px;
      padding-top: 10px;
    }

    &__phone {
      position: static;

      margin-right: 40px;
      padding-top: 8px;

      span {
        font-size: 18px;
      }
    }

    &_opened {
      #{$p}__nav {
        width: 273px;
      }
    }
  }
}

// @media (min-width: 992px) {
//   .header {
//     &__nav {
//       left: calc((100vw - 960px) / -2 - 20px);
//       right: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .header {
    &__content {
      align-items: center;
    }

    &__logo {
      margin-right: 46px;
    }

    &__nav {
      display: flex;
      column-gap: 30px;
      margin-right: auto;

      a {
        font-size: 14px;
        line-height: 1.14;
      }
    }

    &__networks {
      margin-right: 36px;
      padding-top: 0;
    }

    &__phone {
      margin-right: 0;
      padding-top: 0;
    }

    &__button {
      display: none;
    }
  }
}

// @media (hover: hover) {
//   .header {
//     &__nav {
//       a {
//         &:hover {
//           color: map-get($colors, 'main-light');
//         }
//       }
//     }

//     &__phone {
//       &:hover {
//         color: map-get($colors, 'main');
//       }
//     }

//     &__callback {
//       &:hover {
//         color: #fff;
//         background-color: map-get($colors, 'main');
//       }
//     }
//   }
// }
