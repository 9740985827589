.module {
  display: flex;
  flex-direction: column;
}

.city {
  margin-bottom: 10px;
}

.country {
  margin-bottom: 10px;
}

.date {
  margin-bottom: 10px;
}

.nights {
  margin-bottom: 10px;
}

.guests {
  margin-bottom: 10px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  border-radius: 6px;
  color: #fff;
  background-color: #ed1c24;
}

@media (min-width: 768px) {
  .module {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
  }

  .city {
    width: calc(33.33333% - 15px / 3 * 2);
    margin-bottom: 0;
  }

  .country {
    width: calc(33.33333% - 15px / 3 * 2);
    margin-bottom: 0;
  }

  .date {
    width: calc(33.33333% - 15px / 3 * 2);
    margin-bottom: 0;
  }

  .nights {
    width: calc(33.33333% - 15px / 3 * 2);
    margin-bottom: 0;
  }

  .guests {
    width: calc(33.33333% - 15px / 3 * 2);
    margin-bottom: 0;
  }

  .button {
    width: calc(33.33333% - 15px / 3 * 2);
  }
}

@media (min-width: 1200px) {
  .city {
    width: calc(100% / 6 - 15px / 6 * 5);
  }

  .country {
    width: calc(100% / 6 - 15px / 6 * 5);
  }

  .date {
    width: calc(100% / 6 - 15px / 6 * 5);
  }

  .nights {
    width: calc(100% / 6 - 15px / 6 * 5);
  }

  .guests {
    width: calc(100% / 6 - 15px / 6 * 5);
  }

  .button {
    width: calc(100% / 6 - 15px / 6 * 5);
  }
}
